.btn.btn-primary.btn-attempt {
  margin-right: 10px;
}
.btn.btn-primary.answer-true {
  margin-right: 10px;
}
.btn-play-audio {
  margin-right: 10px;
}
#cards label.correct {
  color: #00c000;
}
#cards .pointer {
  cursor: pointer;
}
#cards .btn-readmore {
  margin-bottom: 20px;
  display: block;
}
#cards .btn-play-audio {
  margin-bottom: 10px;
  width: 112px;
}
#cards .panel-success .panel-footer small {
  font-weight: 700;
  color: #00c000;
}
#cards .panel-warning .panel-footer small {
  font-weight: 700;
  color: #ff8000;
}
#cards .panel-danger .panel-footer small {
  font-weight: 700;
  color: red;
}
#cards .panel-footer {
  min-height: 55px;
}
#cards .question-text {
  margin-bottom: 20px;
  font-weight: 700;
  min-height: 39px;
}
.chapter {
  margin-bottom: 25px;
}
.chapter .well.overlay {
  top: 35%;
  left: 35px;
  position: absolute;
  width: 50%;
}
.chapter .background {
  width: 100%;
}
@media only screen and (max-width: 999px) {
  .chapter .well.overlay {
    top: 0;
    left: 0;
    position: relative;
    width: 100%;
  }
}
.panel.charmap input[type='text'] {
  width: 30px;
  text-align: center;
}
.panel.charmap input.alert-danger:disabled {
  color: red;
}
.panel.charmap input.alert-success:disabled {
  color: green;
}
.panel.charmap .table-charmap {
  display: inline-block;
  margin-bottom: -0.5em;
  margin-top: 0.5em;
}
.panel.charmap .table-charmap td {
  text-align: center;
}
.panel.charmap .table.alphabet {
  font-size: 12px;
}
.panel.charmap .table.alphabet td {
  padding: 1px;
  text-align: center;
  width: 3.33%;
}
.panel.choice .image-view {
  display: inline-block;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.panel.choice .image-view label {
  padding-left: 0;
}
.panel.choice .image-view label > * {
  pointer-events: none;
}
.panel.choice .image-view label img {
  width: 100%;
  border-radius: 3px;
  outline: 0 none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.panel.choice .image-view input[type='checkbox'],
.panel.choice .image-view input[type='radio'] {
  display: none;
}
.panel.choice .image-view label input:checked + img {
  box-shadow: 0 1px 1px #000 inset, 0 0 8px 4px rgba(82, 168, 236, 0.8);
}
@media only screen and (max-width: 480px) {
  .panel.choice .image-view .image-label {
    font-size: 0.9em !important;
  }
}
.panel.choice .image-view .image-label {
  text-align: center;
  font-size: 1.1em;
  height: 2.9em;
}
.panel.choice .image-view input:checked + img + .image-label {
  color: #000;
}
.panel.choice .image-view label.correct img {
  box-shadow: 0 1px 1px #000 inset, 0 0 8px 4px #7aba7b !important;
}
.panel.choice .image-view label.wrong img {
  box-shadow: 0 1px 1px #000 inset, 0 0 8px 4px #d59392 !important;
}
.panel.cloze .used {
  text-decoration: line-through;
}
.panel.cloze .solution {
  line-height: 40px;
}
.panel.cloze p {
  margin: 0 0 22.5px;
}
.panel.clozechoice .clozechoices {
  margin-bottom: 30px;
}
#cboxOverlay,
#cboxWrapper,
#colorbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}
#cboxWrapper {
  max-width: none;
}
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}
#cboxBottomLeft,
#cboxMiddleLeft {
  clear: left;
}
#cboxContent {
  position: relative;
}
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
#cboxTitle {
  margin: 0;
}
#cboxLoadingGraphic,
#cboxLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#cboxClose,
#cboxNext,
#cboxPrevious,
#cboxSlideshow {
  cursor: pointer;
}
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}
#cboxContent,
#cboxLoadedContent,
#colorbox {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
#cboxOverlay {
  background: #000;
  opacity: 0.9;
}
#colorbox {
  outline: 0;
}
#cboxContent {
  margin-top: 20px;
  background: #000;
}
.cboxIframe {
  background: #fff;
}
#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}
#cboxLoadedContent {
  border: 5px solid #000;
  background: #fff;
}
#cboxTitle {
  position: absolute;
  top: -20px;
  left: 0;
  color: #ccc;
}
#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0;
  color: #ccc;
}
#cboxLoadingGraphic {
  background: url(../images/loading.gif) no-repeat center center;
}
#cboxClose,
#cboxNext,
#cboxPrevious,
#cboxSlideshow {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: 0 0;
}
#cboxClose:active,
#cboxNext:active,
#cboxPrevious:active,
#cboxSlideshow:active {
  outline: 0;
}
#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: #fff;
}
#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  background: url(../images/controls.png) no-repeat top left;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}
#cboxPrevious:hover {
  background-position: bottom left;
}
#cboxNext {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  background: url(../images/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}
#cboxNext:hover {
  background-position: bottom right;
}
#cboxClose {
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  background: url(../images/controls.png) no-repeat top center;
  width: 38px;
  height: 19px;
  text-indent: -9999px;
}
#cboxClose:hover {
  background-position: bottom center;
}
.panel.correlation .choice-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.panel.correlation .choices-left-row {
  margin-bottom: 50px;
  background: white;
}
.panel.correlation .choices-left {
  height: 75px;
  display: table;
}
.panel.correlation .placeholder {
  height: 75px;
  background: #f0f0f0;
  background-clip: content-box;
  display: table;
}
.panel.correlation .placeholder.active {
  background: #fbf9ee;
  background-clip: content-box;
}
.panel.correlation .placeholder.hover {
  background: #fcefa1;
  background-clip: content-box;
}
.panel.correlation .choice {
  height: 75px;
  cursor: move;
  display: table-cell;
  border: None;
  white-space: normal;
  z-index: 1000;
}
.panel.correlation .choice.active {
  z-index: 1001;
}
.panel.correlation .choice.disabled {
  cursor: not-allowed;
}
.panel.correlation .choice.correct {
  border: 2px solid #7aba7b !important;
  box-shadow: 0 0 8px 4px #7aba7b !important;
  border-radius: 3px;
  outline: 0 none;
}
.panel.correlation .choice.wrong {
  border: 2px solid #d59392 !important;
  box-shadow: 0 0 8px 4px #d59392 !important;
  border-radius: 3px;
  outline: 0 none;
}
#quiz-guide .bubble button.close {
  margin-top: -16px;
}
#quiz-guide .bubble h5.correct {
  color: #00c000;
}
#quiz-guide .bubble h5.retry {
  color: #ff8000;
}
@media only screen and (min-width: 761px) {
  #quiz-execution-view {
    position: relative;
  }
  #quiz-guide {
    position: absolute;
    top: 75px;
    right: -250px;
    display: none;
    z-index: 1000;
  }
  #quiz-guide .guide {
    width: 230px;
  }
  #quiz-guide .smartphone-bar {
    position: absolute;
    top: 46px;
    left: 11px;
    width: 207px;
    padding: 4px 4px 0 4px;
    font-size: 10px;
    z-index: 1;
  }
  #quiz-guide .smartphone-bar.active {
    background-color: #fff;
  }
  #quiz-guide .smartphone-clock {
    position: absolute;
    top: 130px;
    left: 9px;
    width: 211px;
    font-size: 40px;
    text-align: center;
  }
  #quiz-guide .bubble {
    position: absolute;
    top: 44px;
    left: 9px;
    height: 339px;
    width: 211px;
    padding: 26px 5px 5px;
    background: #fff;
    border: #000 solid 2px;
    font-size: 14px;
    overflow-y: auto;
  }
  #quiz-guide .bubble ul {
    padding-left: 20px;
  }
  #quiz-guide .btn-play-audio {
    width: 112px;
  }
  #quiz-execution-view.guide-enabled #quiz-guide {
    display: block;
  }
}
@media only screen and (max-width: 999px) and (min-width: 761px) {
  #quiz-guide .bubble {
    width: 161px;
    height: 255px;
    border: 0;
  }
  #quiz-guide .guide {
    width: 180px;
  }
  #quiz-guide .smartphone-bar {
    top: 36px;
    left: 9px;
    width: 161px;
  }
  #quiz-guide .smartphone-clock {
    top: 90px;
    left: 7px;
    width: 165px;
  }
}
@media only screen and (max-width: 760px) {
  #quiz-guide .guide,
  #quiz-guide .smartphone-bar,
  #quiz-guide .smartphone-clock {
    display: none;
  }
  #quiz-guide .bubble {
    border-top: 1px solid #000;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    width: 100%;
    z-index: 2000;
    overflow-y: scroll;
    max-height: 28%;
  }
  #quiz-guide .bubble button.close {
    margin-top: 9px;
  }
  #quiz-guide .bubble button.close::after {
    content: ' Schließen';
  }
}
.ibrowser iframe {
  height: 100%;
  width: 100%;
}
.ibrowser .modal-lg {
  width: 90%;
}
.panel.imagepointer .question-image {
  cursor: crosshair;
  max-width: 100%;
  position: relative;
}
.panel.imagepointer .click-image,
.panel.imagepointer .click-success-image {
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
}
.panel.imagepointer .click-image {
  background: url(../../staticfiles/images/click.png);
}
.panel.imagepointer .click-success-image {
  background: url(../../staticfiles/images/click-success.png);
}
@media only screen and (max-width: 40em) {
  .panel.memory .memory-desk {
    width: auto !important;
  }
}
.panel.memory .memory-desk {
  margin-left: auto;
  margin-right: auto;
}
.panel.memory .btn-memory-item {
  width: 100px;
  height: 100px;
  margin: 5px;
  white-space: normal;
  padding: 2px;
  background: url(../../staticfiles/images/memory_back.png) 4px 4px no-repeat;
  background-size: 90px 90px;
}
.panel.memory .btn-memory-item.turned {
  background: 0 0;
}
.panel.memory .btn-memory-item img {
  width: 100%;
  height: 100%;
}
.panel.memory .img-memory-item,
.panel.memory .text-memory-item {
  display: none;
}
.panel.memory .text-memory-item {
  word-break: break-word;
}
.panel.memory .finished-cards-area .img-memory-item,
.panel.memory .finished-cards-area .text-memory-item,
.panel.memory .turned .img-memory-item,
.panel.memory .turned .text-memory-item {
  display: inline-block;
}
.panel.memory .found {
  visibility: hidden;
}
.panel.memory .finished-cards-area {
  max-width: 700px;
}
.panel.memory .finished-cards-area .btn-memory-item:nth-child(2n) {
  margin: 30% 0 0 -50%;
}
.panel.memory .finished-cards-area .btn-memory-item {
  background: 0 0;
  width: 60%;
  height: 60%;
}
#quiz-progress-view {
  display: flex;
  align-items: center;
  top: 150px;
  left: 0;
  z-index: 1001;
  background: #f5f5f5;
  color: #337ab7;
  border: #ddd 1px solid;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-radius: 0 4px 4px 0;
}
#quiz-progress-view .btns-pages {
  margin-top: 30px;
}
#quiz-progress-view .btn-expand {
  cursor: pointer;
}
#quiz-progress-view .btn-page {
  height: 20px;
  width: 20px;
  background: #337ab7;
  border: #2e6da4 1px solid;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 2px 5px;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
}
#quiz-progress-view .btn-page.correct {
  background: #5cb85c;
  border-color: #4cae4c;
}
#quiz-progress-view .btn-page.inProgress {
  background: #f0ad4e;
  border-color: #eea236;
}
#quiz-progress-view .btn-page.skipped {
  background: #d9534f;
  border-color: #d43f3a;
}
#quiz-progress-view .btn-page.current {
  border: 3px solid #000;
  padding: 0 2px;
}
#quiz-progress-view .page-title {
  display: none;
}
#quiz-progress-view .navigation-content {
  float: left;
}
#quiz-progress-view .navigation-title > div {
  position: absolute;
  top: 50%;
  right: -25px;
  font-weight: 700;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
#quiz-progress-view .navigation-title {
  width: 20px;
}
#quiz-progress-view > div {
  display: inline-block;
}
#quiz-progress-view.expanded .page-title {
  display: block;
}
#quiz-progress-view.expanded .btn-page {
  width: auto;
}
@media only screen and (max-width: 760px) {
  .navigation-title {
    display: none !important;
  }
  #quiz-progress-view {
    top: 30px;
  }
}
.panel.sequencing input[type='text'] {
  width: 100%;
  margin-bottom: 20px;
}
.panel.sequencing .btn-placeholder,
.panel.sequencing .group-sequencing {
  margin: 0 10px 10px 0;
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap !important;
}
.panel.sequencing .group-sequencing.ui-sortable-helper .btn-left,
.panel.sequencing .group-sequencing.ui-sortable-helper .btn-right {
  opacity: 0.8;
}
.panel.sequencing .sequencing-list {
  padding-left: 0;
  margin-top: 50px;
}
.flex-navigation-title {
  font-weight: 700;
  display: flex !important;
  transform: rotate(-90deg);
  margin: -20px;
}
.table-container {
  display: table;
}
.container-cell {
  display: table-cell;
  vertical-align: top;
}
.modal {
  overflow-y: scroll !important;
}
.tooltip-inner {
  max-width: 110px;
}
.navbar-logo {
  height: 40px;
  padding: 5px 0;
}
.btn-show-modal-help {
  margin-left: 5px;
  margin-bottom: 5px;
}
.btn.finish,
.btn.next,
.btn.previous {
  z-index: 100;
}
#quiz-navigation,
#quiz-navigation .btn.next__ {
  margin-bottom: 90px;
  min-height: 39px;
}
body.mobile .embed-responsive {
  -webkit-overflow-scrolling: touch !important;
  overflow: scroll !important;
}
.container {
  margin-left: 10px !important;
  padding-left: 100px !important;
  padding-right: 235px !important;
}
@media only screen and (max-width: 999px) {
  .container {
    padding-left: 80px !important;
    padding-right: 190px !important;
  }
  .header-title {
    max-width: 330px;
  }
  .logo {
    height: 80px;
  }
  #quiz-guide {
    right: -190px;
  }
}
@media only screen and (max-width: 760px) {
  .container {
    padding-left: 40px !important;
    padding-right: 20px !important;
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 760px) {
  .container > .navbar-header {
    margin-right: 0;
    margin-left: 0;
    float: left;
  }
}
@media only screen and (max-width: 480px) {
  .hidden-lt-480 {
    display: none;
  }
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 90px !important;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
}
.container .text-muted {
  margin: 20px 0;
}
.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}
code {
  font-size: 80%;
}

.form-inline-no-flex {
  display: block;
}
